<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="empresas"
      :loading="loading"
      :search="search"
      :paginate="true"
      sort-by="id_band"
      :lastPage="nLastPage"
      @pageChange="fetchEmpresa(filters, $event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Empresas</v-toolbar-title>
          <v-spacer></v-spacer>

          <RegisterBottom
            v-can-access="125"
            :to="{ name: 'cadastro-empresas' }"
          />
          <FilterEmpresa @selectedFilters="fetchEmpresa((filters = $event))" />
        </v-toolbar>
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>

      <template v-slot:[`item.nome`]="{ item }">
        {{ item.nome | TitleCase }}
      </template>

      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip
          :color="statusAtivo[item.ativo].color"
          dark
          @click="statusItem(item)"
        >
          {{ statusAtivo[item.ativo].text }}
        </v-chip>
      </template>
      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="editItem(item)" color="orange" dark>
          <v-icon small >mdi-pencil</v-icon>
        </v-btn>
      </template> -->
    </BaseTable>
  </div>
</template>

<script>
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import BaseTable from "@/components/shared/NewBaseTable.vue";
import sistema from "@/services/http/sistemaService";
import statusAtivo from "@/mixins/statusAtivo";
import BaseSegmento from "@/components/shared/BaseSegmento";
import FilterEmpresa from "./FilterEmpresa";

export default {
  name: "TabelaEmpresas",

  mixins: [statusAtivo],

  components: {
    BaseTable,
    BaseSegmento,
    FilterEmpresa,
    RegisterBottom
  },

  data() {
    return {
      loading: false,
      filters: {},
      search: "",
      nLastPage: 0,
      headers: [
        { text: "Bandeira", value: "id_band" },
        { text: "Empresa", value: "apelido" },
        { text: "Nome", value: "nome_empresa" },
        { text: "CNPJ", value: "cnpj" },
        { text: "Status", value: "ativo", align: "center" }
        // { text: "Ações", value: "actions", sortable: false }
      ],
      empresas: []
    };
  },

  methods: {
    async fetchEmpresa(filter, nPage = 1) {
      this.loading = true;
      let { data } = await sistema()
        .empresa()
        .show({ ativo: "S", page: nPage, ...filter });
      this.nLastPage = data.last_page;
      this.loading = false;
      this.empresas = data.data;
    }
  },

  async mounted() {
    await this.fetchEmpresa();
  }
};
</script>
